import React from 'react'
import heroImage from 'assets/images/image1.jpeg'
import './hero.Module.scss'

export default function Hero() {
  return (
    <div className="hero">
      <div
        className="hero__image"
        style={{ backgroundImage: `url(${heroImage})` }}
      ></div>
      <div className="hero__container">
        <h1 className="hero__title">Asociatia Fermieri din Romania</h1>
        <p className="hero__text">
          Asociatia Fermieri din Romania se constituie pentru a sprijini toti
          fermierii din Romania. Scopul asociatiei consta in dezvoltarea si
          implementarea de parteneriate intre fermieri din toate tarile europene
          interesati de o colaborare cu fermierii romani si reciproc. Totodata
          asociatia ajuta fermieri romani care intampina probleme cu
          autoritatile centrale si locale (MADR, DAJ, APIA, DSV, Prefectura,
          etc...), precum si in rezolvarea nevoilor individuale ale fiecarui
          fermier.
        </p>
      </div>
    </div>
  )
}
