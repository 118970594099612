import React from 'react'
import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import './aboutMembers.Module.scss'

export default function AboutMembers() {
  return (
    <div className="aboutMembers">
      <SectionTitle title="Despre Membrii" />
      <div className="aboutMembers__container">
        <div className="container__item">
          <label className="item__title">Dobândirea calității de membru</label>

          <div className="item__body">
            <p>• Poate deveni membru al asociației orice persoană (fizică).</p>
            <p>
              • Admiterea în asociație trebuie solicitată în scris la consiliu.
              În cazul minorilor, cererea de admitere trebuie depusă de
              reprezentantul legal. Consiliul decide asupra cererii de aderare
              la propria discreție. El nu trebuie să-i motiveze solicitantului
              dacă cererea este respinsă.
            </p>

            <p>
              • La propunerea consiliului de administrație, adunarea generală
              poate numi membri de onoare pe viață membri sau alte persoane care
              au adus contribuții deosebite asociației.
            </p>
          </div>
        </div>

        <div className="container__item">
          <label className="item__title">Încetarea calității de membru</label>

          <div className="item__body">
            <div className="item__body">
              <p>
                • Calitatea de membru în asociație încetează prin deces (în
                cazul persoanelor juridice, expirarea acestora), demisie sau
                excludere.
              </p>

              <p>
                • Demisia trebuie declarată în scris consiliului de
                administrație. Demisia poate fi declarată numai cu un preaviz de
                trei luni până la sfârșitul exercițiului financiar.
              </p>

              <p>
                • Un membru poate fi exclus din asociație prin hotărâre a
                adunării generale dacă a) dăunează din culpă reputației sau
                intereselor asociației în mod grav sau b) are întârzieri mai
                mari de trei luni la plata taxa de admitere sau de membru și, în
                ciuda unui memento scris sub amenințare de excludere, nu a
                plătit restanțele. Membrul trebuie să aibă posibilitatea de a
                comenta motivele excluderii în cadrul adunării generale. Acestea
                trebuie comunicate acestuia cu cel puțin două săptămâni înainte.
              </p>
            </div>
          </div>
        </div>

        <div className="container__item">
          <label className="item__title">
            Drepturile și obligațiile membrilor
          </label>

          <div className="item__body">
            <div className="item__body">
              <p>
                • Fiecare membru are dreptul de a folosi facilităţile asociaţiei
                şi de a participa la evenimente comune. Fiecare membru are
                același drept de vot și de a alege în adunarea generală.
              </p>

              <p>
                •Fiecare membru are datoria de a promova interesele asociației,
                în special de a-și plăti cotizațiile cu regularitate și, în
                măsura în care este în măsură, de a sprijini viața asociației
                prin cooperarea sa.
              </p>
            </div>
          </div>
        </div>

        <div className="container__item">
          <label className="item__title">
            Taxa de admitere și taxe de membru
          </label>

          <div className="item__body">
            <div className="item__body">
              <p>
                • Fiecare membru trebuie să plătească o cotizație lunară de
                membru, care se datorează în avans.
              </p>

              <p>
                • Cuantumul taxei de admitere și al cotizației de membru se
                stabilește de adunarea generală.
              </p>

              <p>
                • Membrii de onoare sunt scutiți de taxa de admitere și de
                membru.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
