import SectionTitle from 'components/ui/sectionTitle/sectionTitle'
import React from 'react'
import aboutImage from 'assets/images/image4.jpeg'
import './about.Module.scss'

export default function About() {
  return (
    <div className="about">
      <SectionTitle
        title="Introducere"
        subtitle="Mai multe informatii despre noi"
      />

      <div className="about__container">
        <div className="about__body">
          <p className="about__text">
            Asociația se numește Fermieri din Romania (FdR). Traducere în
            germană: Bauern aus Rumänien. Va fi înscrisa în registrul
            asociațiilor și va avea apoi adaosul „e.V.” (eingetragenes Verein).
            Tradus din germana: asociatie inregistrata. Asociația are sediul în
            Ilmenau. Anul financiar este anul calendaristic.
          </p>
        </div>
        <div
          className="about__image"
          style={{ backgroundImage: `url(${aboutImage})` }}
        ></div>
      </div>
    </div>
  )
}
