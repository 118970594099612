import React from 'react'
import './header.Module.scss'
import logoImage from 'assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import HeaderMobile from './components/headerMobile/headerMobile'

export default function Header() {
  const navigate = useNavigate()

  const scrollTo = (id: string) => {
    setTimeout(() => {
      document.querySelector('#' + id)?.scrollIntoView({ behavior: 'smooth' })
    }, 125)
  }

  return (
    <div className="header">
      <div className="header__container">
        <img
          className="header__logo"
          src={logoImage}
          onClick={() => navigate('/')}
        />
        <div className="header__body">
          <label onClick={() => navigate('/')}>Noutati</label>
          <label onClick={() => navigate('/about')}>Despre FdR</label>
          <label
            onClick={() => {
              navigate('/about')
              scrollTo('scopul')
            }}
          >
            Statutul FdR
          </label>

          <label
            onClick={() => {
              navigate('/about')
              scrollTo('inscriere')
            }}
          >
            Inscrie-te
          </label>

          <label
            onClick={() => {
              navigate('/about')
              scrollTo('contact')
            }}
          >
            Contact
          </label>

          <label
            onClick={() => {
              window.open('https://votdiaspora.eu')
            }}
          >
            Vot Diaspora
          </label>

          <label
            onClick={() => {
              window.open(
                'https://www.facebook.com/profile.php?id=100088588365240&sfnsn=scwspwa',
              )
            }}
          >
            Facebook
          </label>
        </div>
        <div className="header__mobile">
          <HeaderMobile />
        </div>
      </div>
    </div>
  )
}
